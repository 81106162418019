
<template>
  <div class="lottery-trend-zodiac">
    <div class="header">
      <div class="title-box">
        <p class="title">花色走势</p>
        <intro-default cont=""></intro-default>
      </div>
      <div class="search-box">
        <div class="switch_fn_bt" @click="rlt_show">{{is_rlt ? '隐藏' : '展开'}}结果</div>
        <select-user type="1" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <!-- <ul class="types">
        <li :class="{active: rankIndex === i }" v-for="(name, i) in rankNames" :key="name" @click="rankIndex = i">{{ name }}</li>
      </ul> -->
      <div class="table"
        id="table">
        <!-- :width="tableWidth" -->
        <table cellpadding="0"
          cellspacing="0"
          class="table-data"
          width="100%"
          ref="table">
          <thead>
            <tr>
              <th scope="col" rowspan="2" colspan="1" width="160">开奖时间</th>
              <th scope="col" rowspan="2" colspan="1" width="120">期号</th>
              <th v-if="is_rlt" rowspan="2" colspan="1" scope="col" width="240">开奖号码</th>
              <th scope="col" rowspan="1" colspan="4" v-for="name in rankNames" :key="name">{{name}}</th>
            </tr>
            <tr>
              <th rowspan="1" colspan="1" v-for="(item, key) in zodiacs" :key="key">{{item}}</th>
            </tr>
          </thead>
          <tbody>
            <tr height="37" v-for="row in tableData" :key="row.expect">
              <td>{{ row.opentime }}</td>
              <td>{{ row.expect }}</td>
              <td v-if="is_rlt">
                <div class="opencode">
                  <span v-for="(code, i) in row.opencode"
                    :key="`${code}-${i}`">{{ code }}</span>
                </div>
              </td>
              <td v-for="(item, i) in row.list" :key="`${item}-${i}`" :style="{backgroundColor: parseInt(i / 4) % 2 === 0 ? '#fff' : '#f9f9f9'}">
                <span v-if="typeof item === 'string'" class="hot" :class="hot_fn(i)" ref="hots">{{ item }}</span>
                <template v-else>{{item}}</template>
              </td>
            </tr>
          </tbody>
        </table>
        <trend-line-canvas v-for="obj in lineObjects"
          :key="obj.id"
          :width="obj.width"
          :height="obj.height"
          :start="obj.start"
          :end="obj.end"
          :color="obj.color"
          :line-width="obj.lineWidth"
          :top="obj.top"
          :left="obj.left"></trend-line-canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading, Select, Option } from 'element-ui'
import TrendLineCanvas from '@components/pc/TrendLineCanvas'
import randomString from '@plugins/random'
import moment from 'moment'

export default {
  name: 'TrendZodiac',
  props: {
    code: String,
    type: String
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [TrendLineCanvas.name]: TrendLineCanvas
  },
  data () {
    return {
      per_page: 10,
      is_rlt: true,
      expectWidth: 150,
      opencodeWidth: 240,
      codeWidth: 40,
      codeHeight: 37,
      data: [],
      loading: null,
      options: [
        {
          label: '40期',
          value: 40
        },
        {
          label: '90期',
          value: 90
        },
        {
          label: '今日所有',
          value: null
        }
      ],
      page: null,
      lineObjects: [],
      rankIndex: 0,
      zodiacs: ['方块', '梅花', '红桃', '黑桃', '方块', '梅花', '红桃', '黑桃', '方块', '梅花', '红桃', '黑桃', '方块', '梅花', '红桃', '黑桃']
    }
  },
  computed: {
    opencodeLength () {
      const [row] = this.data
      if (!row) return 0
      return row.opencode.length
    },
    rankNames () {
      const arr = this.get_type_name(this.code)
      return arr
    },
    tableData () {
      const arr = []
      for (const { opentime, expect, list, opencode } of this.data) {
        let a = []
        for (let i in list) {
          a = a.concat(list[i])
        }
        arr.push({
          opentime: this.normal_time(opentime),
          expect,
          list: a,
          opencode
        })
      }
      return arr
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetData()
    },
    page: function (val, oldVal) {
      this.requestGetData()
    },
    type: function (val, oldVal) {
      this.requestGetData()
    },
    rankIndex: function (val, oldVal) {
      this.$nextTick(() => {
        this.drawLine()
      })
    }
  },
  mounted () {
    this.requestGetData()
  },
  methods: {
    normal_time(date) {
      return moment(parseInt(date) * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    page_change (value) {
      this.per_page = value
      this.requestGetData()
    },
    hot_fn (i) {
      return 'hot' + parseInt(i / 4)
    },
    rlt_show () {
      this.is_rlt = !this.is_rlt
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    refresh () {
      this.requestGetData()
    },
    requestGetData () {
      this.showTableLoading()
      this.$api.decorTrend(this.code, this.per_page).then((res) => {
        this.data = res.data
        this.hideTableLoading()
        this.$nextTick(() => {
          this.drawLine()
        })
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading () {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading () {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    },
    drawLine () {
      const arr = []
      const lineWidth = 1

      const tableRect = this.$refs.table.getBoundingClientRect()

      const hots = this.getHotElement()
      for (var rank = 0; rank < hots.length; rank++) {
        const group = hots[rank]
        if (typeof group === 'object') {
          var lastCenter = null

          for (let index = 0; index < group.length; index++) {
            const hot = group[index]
            const hotRect = hot.getBoundingClientRect()
            const x = hotRect.x - tableRect.x + hotRect.width / 2
            const y = hotRect.y - tableRect.y + hotRect.height / 2
            const center = {
              x: x,
              y: y
            }
            if (!lastCenter) {
              lastCenter = center
              continue
            }

            var obj = {
              lineWidth,
              color: '#1D8DDE',
              id: `${rank}-${index - 1}-${index}`
            }

            const canvasWidth = Math.abs(lastCenter.x - x)
            const canvasHeight = Math.abs(lastCenter.y - y)

            obj.width = canvasWidth
            obj.height = canvasHeight

            if (lastCenter.x > x) {
              obj.start = {
                x: canvasWidth,
                y: 0
              }
              obj.end = {
                x: 0,
                y: canvasHeight
              }

              obj.left = x
              obj.top = lastCenter.y
            } else if (lastCenter.x < x) {
              obj.start = {
                x: 0,
                y: 0
              }
              obj.end = {
                x: canvasWidth,
                y: canvasHeight
              }

              obj.left = lastCenter.x
              obj.top = lastCenter.y
            } else {
              obj.start = {
                x: lineWidth / 2,
                y: 0
              }
              obj.end = {
                x: lineWidth / 2,
                y: canvasHeight
              }

              obj.left = (lastCenter.x - lineWidth / 2)
              obj.top = lastCenter.y
              obj.width = lineWidth
            }

            arr.push(obj)
            lastCenter = center
          }
        }
      }
      this.lineObjects = arr
    },
    getHotElement () {
      var arr = []
      for (let i = 0; i < 4; i++) {
        arr[i] = document.getElementsByClassName('hot' + i)
      }
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.lottery-trend-zodiac {
  > .header {
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      border-bottom: thin $color-border solid;

      > .title-box {
        display: flex;
        align-items: center;
        height: 49px;

        > .title {
          font-size: 20px;
          font-weight: 400;
          color: #222;
        }
      }

      > .search-box {
        display: flex;
        align-items: center;

        > .title {
          margin-right: 15px;
          font-size: 14px;
          color: #222;
        }

        > .icon-search {
          margin-left: 10px;
          width: 48px;
          height: 24px;
          display: block;
        }
      }
    }
    .lz_cont {
      padding: 20px;
      padding-top: 30px;
      padding-bottom: 0;
      box-sizing: border-box;
    }

  .types {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;

    > li {
      cursor: pointer;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      color: #858586;
      background-color: #E3E3E5;
      width: 70px;
      height: 24px;
      border-radius: 4px;

      &.active {
        color: #FFFFFF;
        background-color: #FA3E3E;
      }

      & + li {
        margin-left: 10px;
      }
    }
  }


  .table {
    position: relative;

    > .table-data {
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
      border-collapse: separate;
      border: 1px solid #d6d6d6;
      border-right: none;
      border-bottom: none;

      .el-table thead {
        color: #909399;
        font-weight: bold;
      }

      td,
      th {
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
        text-align: center;
        border-bottom: 1px solid #d6d6d6;
        border-right: 1px solid #d6d6d6;
        font-size: 14px;
      }

      th {
        height: 40px;
        font-weight: bold;
        background-color: #f9f9f9;
        color: rgba(0, 0, 0, 0.5);
      }

      td {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .opencode {
      color: #fa3e3e;

      span + span {
        margin-left: 5px;
      }
    }

    .hot {
      position: relative;
      z-index: 100;
      display: inline-block;
      color: white;
      background: #07A6F8;
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      text-align: center;
      border-radius: 50%;
    }
  }
}
.sum-tip {
  padding: 12px;
  > .title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  > .content {
    margin-top: 8px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
}
.switch_fn_bt {
  margin-right: 20px;
  width: 80px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  background-color: #F9F9F9;
  color: #222;
  border: 1px solid #E3E3E6;
  cursor: pointer;
}
</style>
